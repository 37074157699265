<template>
<span>Dokumentacja</span>
</template>

<script>
export default {
  name: "Documentation",
  beforeRouteEnter() {
    window.location.href = "https://qamilnowak.gitbook.io/hwrepo/";
  }
}
</script>
